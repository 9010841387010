<template>
  <div>
    <Edit :item="currentItem" :update="updateItem" :close="closeEdit"></Edit>
    <Extract :item="currentItem" :close="closeExtract"></Extract>
    <Loading :isActive="loading"></Loading>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab :title="descricaoPluralModulo('ADMIN_CONTA_CORRENTE')">
        <div class="filters row" style="margin-top:0">
          <div class="col-1-2">
            <span class="filters__search">
              <input
                      type="text"
                      v-model="term"
                      placeholder="Buscar por..."
                      v-on:input="debounceLoadContaCorrente()"
              />
              <img
                      style="position: relative; right: -10px;"
                      src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
              />
            </span>
          </div>
        </div>
        <div>
          <div class="table-responsive">
            <datatable :columns="table.columns" :data="items" class="table table-hover">
              <template slot-scope="{ row }">
                <tr>
                  <td> {{ row.colaborador_id }} </td>
                  <td> {{ row.nome }} </td>
                  <td> {{ toMonetary(row.saldo_atual) }} </td>
                  <td class="actions">
                    <button
                            type="button"
                            class="button button-primary"
                            v-on:click="openEdit(row)"
                    >
                      <fa-icon icon="pencil-alt" />
                    </button>
                    <button
                            type="button"
                            class="button button-info"
                            v-on:click="openExtract(row)"
                    >
                      <fa-icon icon="info-circle"/>
                    </button>
                  </td>
                </tr>
              </template>
            </datatable>
          </div>
          <div style="display: flex; justify-content: center;">
            <pagination
                    :data="table.paginator"
                    :limit="5"
                    @pagination-change-page="loadContaCorrente"
            ></pagination>
          </div>
        </div>
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import clone from 'just-clone';
import _debounce from 'lodash/debounce';
import { get, put } from '../../helpers/apiRequest';
import Edit from './Edit';
import Extract from './Extract';
import { descricaoPluralModulo, descricaoSingularModulo } from '@/helpers/cargas';
import { toMonetary } from '@/helpers/utils';

export default {
  name: 'conta-corrente',
  components: {
    Edit,
    Extract
  },
  data() {
    return {
      loading: false,
      items: [],
      term: '',
      currentItem: {},
      table: {
        filter: '',
        columns: [
          { label: 'ID',    field: 'colaborador_id',  align: 'center', sortable: false },
          { label: 'Nome',  field: 'nome',            align: 'center', sortable: false },
          { label: 'Saldo', field: 'saldo_atual',     align: 'center', sortable: false },
          { label: '', field: '', sortable: false },
        ],
        paginator: {},
      },
    };
  },
  computed: {},
  methods: {
    descricaoPluralModulo,
    descricaoSingularModulo,
    toMonetary,
    loadContaCorrente(page = 1, take = 15) {
      get(`/admin/conta-corrente?page=${page}&take=${take}&q=${this.term}`)
        .then((json) => {
          this.items = json.itens;
          this.table.paginator = json.paginator;
          this.loading = false;
        })
        .catch((err) => (this.loading = false));
    },
    debounceLoadContaCorrente: _debounce(function() {
      this.loadContaCorrente();
    }, 300),
    openEdit(item) {
      this.currentItem = clone(item);
      this.$modal.show('cEdit');
    },
    closeEdit() {
      this.$modal.hide('cEdit');
    },
    openExtract(item) {
      this.currentItem = clone(item);
      this.$modal.show('cExtract');
    },
    closeExtract() {
      this.$modal.hide('cExtract');
    },
    updateItem() {
      const { colaborador_id } = this.currentItem;
      put(`/admin/conta-corrente/${colaborador_id}`, this.currentItem)
        .then((json) => {
          this.closeEdit();
          this.loadContaCorrente();
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });          
        })
        .catch((error) => {
          this.$swal({
            title: 'Falha ao Salvar',
            text: error.message,
            showCancelButton: true,
            cancelButtonText: 'Fechar',
            showConfirmButton: false,
            icon: 'error',
          });
        });
    },
  },
  beforeMount() {
    this.loading = true;
    this.loadContaCorrente();
  },
};
</script>
