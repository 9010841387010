<template>
  <modal name="cEdit" height="auto" width="900" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab title="Dados Cadastrais">
            <div class="form">
              <div class="xrow">
                <div class="col-1-4">
                  <div class="form-group">
                    <label for="">ID</label>
                    <input type="text" class="form-control" readonly disabled v-model="item.colaborador_id">
                  </div>
                </div>
                <div class="col-3-4">
                  <div class="form-group">
                    <label for="">Nome</label>
                    <input type="text" class="form-control" readonly disabled v-model="item.nome">
                  </div>
                </div>
                <div class="col-1-4" v-show="!!item.tipo">
                  <div class="form-group">
                    <label for="">Tipo</label>
                    <input type="text" class="form-control" readonly disabled v-model="item.tipo">
                  </div>
                </div>
                <div class="col-1-4" v-show="!!item.operador_id">
                  <div class="form-group">
                    <label for="">Operador ID</label>
                    <input type="text" class="form-control" readonly disabled v-model="item.operador_id">
                  </div>
                </div>
                <div class="col-1-4" v-show="!!item.representante_id">
                  <div class="form-group">
                    <label for="">Representante ID</label>
                    <input type="text" class="form-control" readonly disabled v-model="item.representante_id">
                  </div>
                </div>
                <div class="col-1-4" v-show="!!item.supervisor_id">
                  <div class="form-group">
                    <label for="">Supervisor ID</label>
                    <input type="text" class="form-control" readonly disabled v-model="item.supervisor_id">
                  </div>
                </div>
                <div class="col-1-4">
                  <div class="form-group">
                    <label for="">Saldo</label>
                    <money class="form-control" v-model="item.saldo_atual" v-bind="monetary" />
                  </div>
                </div>
                <div class="col-1-1">
                  <div class="form-group">
                    <label for="">Observação</label>
                    <input type="text" class="form-control" v-model="item.observacao">
                  </div>
                </div>
              </div>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <button class="button button-success" v-on:click="handleSubmit()">
        Alterar
      </button>
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>
<script>
import CloseModal from "../../components/CloseModal";

export default {
  name: "cEdit",
  components: { CloseModal },
  props: ["update", "close", "item"],
  data() {
    return {
      monetary: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        masked: false,
      },
    };
  },
  methods: {
    handleSubmit() {      
      this.update();      
    },
  },
};
</script>
